export const EventType = {
  INPUT: 'INPUT', // 设备录入
  READY: 'READY', // 启用
  TERMINATED: 'TERMINATED', // 停用
  INSPECTION: 'INSPECTION', // 巡检
  MAINTAIN: 'MAINTAIN', // 保养
  REPAIR: 'REPAIR', // 维修
};

export const EventTypeText = {
  INPUT: '设备录入', // 设备录入
  READY: '启用', // 启用
  TERMINATED: '停用', // 停用
  INSPECTION: '巡检', // 巡检
  MAINTAIN: '保养', // 保养
  REPAIR: '维修', // 维修
};

export const AttachmentCategory = {
  // 设备信息
  EQUIPMENT_PHOTO: 'EQUIPMENT_PHOTO', // 设备图片
  EQUIPMENT_CERTIFICATION: 'EQUIPMENT_CERTIFICATION', // 设备合格证
  EQUIPMENT_REGISTRATION: 'EQUIPMENT_REGISTRATION', // 设备注册证

  // 工单
  MP_TICKET_PHOTO: 'MP_TICKET_PHOTO', // 纸质工单照片
  MP_REPAIR_FAILURE: 'MP_REPAIR_FAILURE', // 报修故障图片
  MP_REPAIR_FAILURE_VOICE: 'MP_REPAIR_FAILURE_VOICE', // 报修语音
  MP_MAINTAIN: 'MP_MAINTAIN', // 保养单图片（总）
  MP_MAINTAIN_ITEM: 'MP_MAINTAIN_ITEM', // 保养项目图片

  // 人员
  EMPLOYEE_AVATAR: 'EMPLOYEE_AVATAR', // 用户头像

  // 组织
  ORGANIZATION_LOGO: 'ORGANIZATION_LOGO', // 组织机构Logo

  // 签约
  CONTRACT_PHOTO: 'CONTRACT_PHOTO', // 合同照片
};

export const ResourcePath = `//${
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1')
    ? 'yxkdev.bjyixiu.com'
    : window.location.host
}/v1/image/get/`;

export const AudioResourcePath = `//${
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1')
    ? 'yxkdev.bjyixiu.com'
    : window.location.host
}/v1/att/get/`;

export const EquipmentWarrantyStatusOptions = [
  {
    value: 'MANUFACTURER',
    label: '厂家保修',
  },
  {
    value: 'THIRDPART',
    label: '第三方保修',
  },
  {
    value: 'OTHER',
    label: '其它保修',
  },
  {
    value: 'NONE',
    label: '非在保',
  },
];

export const WarranthyStatus = {
  ALL: 'ALL',
  MANUFACTURER: 'MANUFACTURER',
  THIRDPART: 'THIRDPART',
  OTHER: 'OTHER',
  NONE: 'NONE',
};

export const WarranthyStatusText = {
  ALL: '所有',
  MANUFACTURER: '厂家保修',
  THIRDPART: '第三方保修',
  OTHER: '其它保修',
  NONE: '非在保',
};

export const EquipmentSourceTypeText = {
  PURCHASED: '购买',
  GIFT: '馈赠',
  BORROWED: '借调',
  ALLOTTED: '调拨',
};

export const EquipmentStatusText = {
  UNUSED: {
    label: '闲置',
    color: '#DB1E32',
  },
  READY: {
    label: '使用中',
    color: '#6ea900',
  },
  LENT: {
    label: '外调',
    color: '#DB1E32',
  },
  TERMINATED: {
    label: '终止',
    color: '#DB1E32',
  },
};

export const EquipmentPurchaseMethodText = {
  // 'SELF': {"key": "SELF", "value": "自行购买"},
  // 'FUND': {"key": "FUND", "value": "社会集资"},
  INQUERY: '询价购置',
  BID: '招标购置', // 公开招标
  SINGLE: '单一来源购置',
  NEGOTIATION: '竞争性谈判购置',
};
