<template>
  <div>
    <div class="uploader-wrapper">
      <div class="btn-add" @click="handleAddImage" v-if="!preview">
        <img src="@/assets/add_image_dashed.png" alt="">
      </div>
      <van-image
        v-for="(item, index) in imageList"
        :key="item.localId"
        :src="item.localId"
        @click="handlePreview(item, index)"
        class="image-item" />
    </div>
    <van-image-preview
      :start-position="currentPreviewIndex"
      v-model="previewVisible"
      :images="previewImages"
      @change="handlePreviewChange"
    >
      <template v-slot:index>{{ currentPreviewIndex + 1 }}/{{ previewImages.length }}</template>
      <template v-slot:cover><div class="preview-cover-wrapper" v-if="!preview"><a @click="handleDeleteImage">删除</a></div></template>
    </van-image-preview>
  </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import { uploadImage2Wechat } from '@/services/wx';
import { uploadMedia } from '@/services/qiniu';
import {
  ResourcePath,
  AttachmentCategory,
} from '@/utils/constant';

export default {
  name: 'yxk-uploader',
  emits: ['onChange'],
  props: {
    imageList: Array,
    preview: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      previewVisible: false,
      currentPreviewIndex: 0,
    }
  },
  computed: {
    previewImages () {
      return this.imageList.map(item => `${ResourcePath}${item.res}`)
    }
  },
  methods: {
    handlePreviewChange(index) {
      this.currentPreviewIndex = index;
    },
    handleDeleteImage() {
      const toDeleteItem = this.imageList.find((_, index) => index === this.currentPreviewIndex);
      this.$emit('on-change', {
        type: 'delete',
        payload: {
          localId: toDeleteItem.localId,
        }
      })
      this.previewVisible = false;
    },
    handleAddImage() {
      window.wx.chooseImage({
        count: 5, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: async (res) => {
          if ((this.imageList.length + res.localIds.length) > 5) {
            Toast('最多只能上传5张图片');
            return;
          }
          const uploadData = await uploadImage2Wechat(res.localIds);
          for (const item of uploadData) {
            try {
              const { code, data } = await uploadMedia(1, item.serverId);
              if (code === 0) {
                this.$emit('on-change', {
                  type: 'add',
                  payload: {
                    localId: item.localId,
                    res: data.res,
                    contentLength: data.fileSize,
                    contentType: data.fileMimeType,
                    category: AttachmentCategory.MP_REPAIR_FAILURE,
                  }
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
        },
        fail: (error) => {
          console.error(error);
        }
      });
    },
    handlePreview(item, index) {
      this.currentPreviewIndex = index;
      this.previewVisible = true;
    }
  },
}
</script>

<style lang="less">
  .uploader-wrapper {
    width: 100%;
    overflow: hidden;
    .btn-add {
      display: inline-block;
      overflow: hidden;
      width: 64px;
      height: 64px;
      margin-right: 10px;
      margin-bottom: 5px;
      img {
        width: 100%;
      }
    }

    .image-item {
      display: inline-block;
      width: 64px;
      height: 64px;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
  .van-image-preview__cover {
    width: 100%;
    text-align: right;
  }
  .preview-cover-wrapper {
    width: 100%;
    color: #fff;
    font-weight: 700;
    padding-right: 25px;
    padding-top: 10px;
    font-size: 15px;
    color: red;
  }
</style>
