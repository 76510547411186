import request from "@/utils/request";

/**
 * 获取七牛上传图片token
 * @param {*} id
 * @returns
 */
export async function fetchUploadToken() {
  return request("/v1/image/token");
}

/**
 * 上传图片到后端服务
 * @param {*} mediaType
 * @param {*} mediaId
 * @returns
 */
export async function uploadMedia(mediaType, mediaId) {
  return request("/v3/file/uploadImgFromWeiXin", {
    method: "POST",
    data: {
      fileType: mediaType,
      mediaId,
    },
  });
}

export async function fetchOriginalDownloadUrl(key) {
  return request(`/v1/att/url_with_expire/${key}`);
}

/**
 * 查询音频元信息
 * @param {*} key
 * @returns
 */
export async function fetchAvinfo(key) {
  return request(`/v1/att/avinfo/${key}`);
}
